
#my_investment_chart {
	min-width: 310px;
	max-width: 400px;
	height: 210px;
	margin: 0 auto
}

#portfolio_pie {
    min-width: 310px;
    max-width: 400px;
    height: 210px;
    margin: 0 auto
}

