$robotic-purple: #24255D;
$robotic-gold: #BF935B;
$color-pale-1: #fff0ed;
$color-pale-2: #edf0ff;
$color-pale-3: #feffed;
$color-pale-4: #fcedff;
$color-pale-5: #efffed;
$color-pale-6: #edfbff;

// Bootstrap variable overrides
$theme-colors: (
    "primary": $robotic-purple
);

button.card-action {
    position: relative;
    left: 8px;
}

// Alerts for form errors
.alert ul.errorlist {
    padding: 0;
    margin: 0;
    list-style-type: none;
}

@import "index";
@import "node_modules/bootstrap/scss/bootstrap";
@import "common";
@import "forms";
@import "navigation";
@import "company_detail";
@import "article_list";
