.company_detail {
    .comment {
        background-color: $color-pale-2;
    }

    table.figures {
        th {
            width: 65%;
        }
        td {
            width: 35%;
        }
    }

    ul.recent-articles {
        list-style-type: none;
        padding-left: 0;

        li {
            margin-top: 1rem;
        }
    }
}